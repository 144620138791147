


















































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import SidebarComponent from "./Sidebar.component";
@Component
export default class Sidebar extends Mixins(SidebarComponent) {}
