import { Component, Vue } from "vue-property-decorator";
import VueRouter from 'vue-router'
import Model from "@/models/Model";

Vue.use(VueRouter)


@Component
default class Sidebar extends Vue {
  private model: Model;

  private nav: any = {
    staticConfig: false,
    profile: false,
    management: false,
    report: false,
    payment: false
  };

  private nav2: any = {
    staticConfig: false,
    home: false,
  };

  private error = null;
  private navOpen: any = "";
  private cookieData: any = Vue.$cookies.get("login")
  private role = ""
  private sideActive = true
  private mainUser = true
  private profile: any = this.$store.state.profile
  private subAccountMenus = {
    staticConfig: false,
    profile: false,
    changePassword : false,
    management: false,
    report: false,
    payment: false,
    addAgent: false,
    addProduct: false,
    listProduct: false,
    memberAgent: false,
    transfer: false,
    statement: false
  }

  constructor() {
    super();
    this.model = new Model();
  }

  async mounted() {
    // console.log(this.profile)
    if (this.cookieData) {
      if (Object.prototype.hasOwnProperty.call(this.cookieData, 'mainUsername')) {
        this.sideActive = false
        this.mainUser = false
        if (Object.prototype.hasOwnProperty.call(this.$store.state.profile, 'subAccountMenus')) {
          this.$store.state.profile.subAccountMenus.forEach(val => {
            if (val.name == 'MENU_ACCOUNT') {
              if (val.permission == 'hidden') {
                this.subAccountMenus.profile = false
                this.subAccountMenus.changePassword = false
              } else if (val.permission == 'readonly') {
                this.subAccountMenus.profile = true
                this.subAccountMenus.changePassword = false
              } else if (val.permission == 'editable') {
                this.subAccountMenus.profile = true
                this.subAccountMenus.changePassword = true
              }
            }
            if (val.name == 'MENU_PRODUCT') {
              if (val.permission == 'hidden') {
                this.subAccountMenus.staticConfig = false
              } else if (val.permission == 'readonly') {
                this.subAccountMenus.staticConfig = true
                this.subAccountMenus.listProduct = true
              } else if (val.permission == 'editable') {
                this.subAccountMenus.staticConfig = true
                this.subAccountMenus.addProduct = true
                this.subAccountMenus.listProduct = true
              }
            }
            if (val.name == 'MENU_AGENT') {
              if (val.permission == 'hidden') {
                this.subAccountMenus.management = false
                this.subAccountMenus.addAgent = false
                this.subAccountMenus.memberAgent = false
              } else if (val.permission == 'readonly') {
                this.subAccountMenus.management = true
                this.subAccountMenus.addAgent = false
                this.subAccountMenus.memberAgent = true
              } else if (val.permission == 'editable') {
                this.subAccountMenus.management = true
                this.subAccountMenus.addAgent = true
                this.subAccountMenus.memberAgent = true
              }
            }
            if (val.name == 'MENU_REPORT') {
              if (val.permission == 'hidden') {
                this.subAccountMenus.report = false
              } else if (val.permission == 'readonly' || val.permission == 'editable') {
                this.subAccountMenus.report = true
              }
            }
            if (val.name == 'MENU_CREDIT') {
              if (val.permission == 'hidden') {
                this.subAccountMenus.payment = false
                this.subAccountMenus.transfer = false
                this.subAccountMenus.statement = false
              } else if (val.permission == 'readonly' || val.permission == 'editable') {
                this.subAccountMenus.payment = true
                this.subAccountMenus.transfer = true
                this.subAccountMenus.statement = true
              }
            }
          });
        }
      }
      this.role = this.cookieData.roles[0]
    }
  }

  get routeName(): Model {
    const todos = this.$store.state.todos.Name;
    return todos;
  }

  public toggleAccordion(nav: string) {
    switch (nav) {
        case "profile":
          this.navOpen = "profile";
          break;  
      case "management":
        this.navOpen = "management";
        break;
      case "report":
        this.navOpen = "report";
        break;
      default:
        break;
    }
    Object.keys(this.nav).forEach(key => {
      if (key === this.navOpen) {
        this.nav[key] = !this.nav[key];
      } else {
        this.nav[key] = false;
      }
    });
  }
}
export default Sidebar