












import { Component, Mixins } from "vue-property-decorator";
import FooterComponent from "./Footer.component";
@Component
export default class Home extends Mixins(FooterComponent) {}
