import API from './api'
// import axios from 'axios'
import { axiosWithErrorHandler } from './axios'

export const GET_DASHBOARD = async (id: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_DASHBOARD}/${id}`)
    return response.data;
};

export const GET_RATEUS = async () => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.RATE_US}`)
    return response.data;
};

export const GET_COMMENT = async (startDate: string, endDate: string, page: number, limit: number) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.COMMENT}/?page=${page}&pageSize=${limit}&startDate=${startDate}&endDate=${endDate}`)
    return response.data;
};

export const CREATE_SUB_ACCOUNT_AGENT = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.SUB_ACCOUNT_AGENT}`, state)
    return response.data;
};

export const EDIT_SUB_ACCOUNT_AGENT = async (state: any) => {
    const response = await axiosWithErrorHandler(`PUT`, `${API.API_URL}${API.SUB_ACCOUNT_AGENT}`, state)
    return response.data;
};

export const GET_SUB_ACCOUNT_AGENT = async (page: number, limit: number) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.SUB_ACCOUNT_AGENT}?page=${page}&pageSize=${limit}`)
    return response.data;
};

export const GET_LOGIN_HISTORY_AGENT = async (userId: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.LOGIN_HISTORY_AGENT}/${userId}`)
    return response.data.data;
};

export const CREATE_AGENT = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.AGENT}`, state)
    return response.data;
};

export const EDIT_AGENT = async (state: any) => {
    const response = await axiosWithErrorHandler(`PUT`, `${API.API_URL}${API.AGENT}`, state)
    return response.data;
};

export const CREATE_MEMBER = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.MEMBER}`, state)
    return response.data;
};

export const EDIT_MEMBER = async (state: any) => {
    const response = await axiosWithErrorHandler(`PUT`, `${API.API_URL}${API.MEMBER}`, state)
    return response.data;
};

export const UPDATE_CALLBACK = async (state: any) => {
    const response = await axiosWithErrorHandler(`PUT`, `${API.API_URL}${API.CALLBACK}`, state)
    return response.data;
};

export const UPDATE_WHITELIST = async (state: any) => {
    const response = await axiosWithErrorHandler(`PUT`, `${API.API_URL}${API.WHITELIST}`, state)
    return response.data;
};

export const DELETE_WHITELIST = async (state: any) => {
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.WHITELIST}`, state)
    return response.data;
};

export const GET_PROFILE_AGENT = async (userId: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.PROFILE_AGENT}/${userId}`)
    return response.data.data;
};

export const GET_PROFILE_MEMBER = async (userId: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.PROFILE_MEMBER}/${userId}`)
    return response.data.data;
};

export const GET_ROYALTY_SETTING_BY_ID = async (id: string | (string | null)[]) => {
    // const response = await axios.get(`${API.API_URL}${API.ROYALTY_SETTING}/${id}`)
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.ROYALTY_SETTING}/${id}`)
    return response.data.data;
};

export const GET_API_LICENSE_BY_ID = async (id: string | (string | null)[]) => {
    // const response = await axios.get(`${API.API_URL}${API.GET_API_LICENSE_BY_ID}/${id}`)
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_API_LICENSE_BY_ID}/${id}`)
    return response.data.data;
};

export const UPDATE_PROFILE = async (state: any) => {
    const response = await axiosWithErrorHandler(`PUT`, `${API.API_URL}${API.UPDATE_PROFILE}`, state)
    return response.data;
};

export const CHANGE_PASSWORD_AGENT = async (state: any) => {
    const response = await axiosWithErrorHandler(`PUT`, `${API.API_URL}${API.CHANGE_PASSWORD_AGENT}`, state)
    return response.data;
};

export const GET_AGENT_LIST = async (page: number, limit: number) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.AGENT}/?page=${page}&pageSize=${limit}`)
    return response.data.data;
};

export const GET_AGENT_LIST_BY_ID = async (id: string | (string | null)[], page: number, limit: number) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.AGENT_LIST}/${id}/?page=${page}&pageSize=${limit}`)
    return response.data.data;
};

export const GET_CHILDREN_LIST_BY_ID = async (id: string | (string | null)[], page: number, limit: number) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.CHILDREN}/${id}/?page=${page}&pageSize=${limit}`)
    return response.data.data;
};

export const GET_HISTORY_AGENT = async (id: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_HISTORY_AGENT}/?id=${id}`)
    return response.data;
};

export const GET_HISTORY_AGENT_BY_DATE = async (id: string, date: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_HISTORY_AGENT}/?id=${id}&date=${date}`)
    return response.data;
};

export const GET_HISTORY_MEMBER = async (id: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_HISTORY_MEMBER}/?id=${id}`)
    return response.data;
};

export const GET_HISTORY_MEMBER_BY_DATE = async (id: string, date: string) => {
    const response = await axiosWithErrorHandler(`GET`, `${API.API_URL}${API.GET_HISTORY_MEMBER}/?id=${id}&date=${date}`)
    return response.data;
};

export const getRoyaltySettingLimit = async (state: any) => {
    // const response = await axios.post(`${API.API_URL}${API.GET_ROYALTY_SETTING_LIMIT}`, state)
    const response = await axiosWithErrorHandler(`POST`, `${API.API_URL}${API.GET_ROYALTY_SETTING_LIMIT}`, state)
    return response.data;
};
//